form:has(.whisperer__list){
	position: relative;
}

.whisperer__list{
	padding: 0;
	margin: 0;
	list-style-type: none;
	font-size: .9em;
	@include min(1100px){
		.fullscreen-search &{
			font-size: 1.1em;
		}
	}
	&.-root{
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 20;
		background-color: $vismo-color-light;
		max-height: 22em;
		overflow-y: auto;
		color: $vismo-color-dark;
		.fullscreen-search &{
			max-height: 50vh;
		}
	}

	.whisperer__title{
		display: flex;
		color: #a4a4a4;
		padding: .5em 1em;
		&:after {
			flex: 1;
			content: "";
			align-self: center;
			margin-left: 15px;
			border-bottom: 1px dashed #e0e0e0;
	  }
	}

	.whisperedItem{
		cursor: pointer;
		padding: .5em 1em;
		&.-selectedWhisperedItem{
			text-decoration: underline;
			background-color: #dddddd;
		}
	}

	@include link-over(".whisperedItem"){
		text-decoration: underline;
		background-color: #dddddd;
	}
}
