.pgn {
	&-section {
		display: flex;
		align-items: baseline;
		gap: 0 8%;
		flex-wrap: wrap;
		margin: 0 0 3em 0;
	}

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		@include flex(df, fww, aic);
		gap: 0 .5rem;
	}

	&__item {
		display: inline-flex;
		line-height: 1;
		&.-first,
		&.-previous{
			margin-right: 1rem;
		}
		&.-active a[aria-disabled='true']{
			font-weight: bold;
			opacity: 1;
		}
		&.-next,
		&.-last{
			margin-left: 1rem;
		}
	}

	&__link {
		display: inline-flex;
		svg {
			width: auto;
			height: .75rem;
			fill: none;
			stroke: currentColor;
			stroke-width: 2;
			stroke-linecap: round;
			stroke-miterlimit: 10;
		}
	}
}

@include link-all(".pgn__link[aria-disabled='true']") {
	pointer-events: none;
	text-decoration: none;
	opacity: .65;
}
