.org-opening-hours{
    &__list,
    &-container__list{
        padding-left: 0;
        list-style-type: none;
        margin: 0 0 2em;
    }
    &__item{
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        line-height: 1.6;
    }
    &__description{
        width: 100%;
        margin: 0 0 1rem;
    }
    &__day{
        min-width: 5.5em;
    }
    &__time{
        margin: 0;
        display: inline-flex;
        flex-wrap: wrap;
    }
}