.overlay {
	background-color: rgba(#000, .6);
	display: block;
	position: absolute;
	z-index: $vismo-overlay-index;

	&.-left,
	&.-right {
		&::before,
		&::after {
			content: "";
			position: absolute;
			width: 14px;
			height: 14px;
			opacity: .6;
			background: {
				repeat: no-repeat;
				color: transparent;
				position: 0 0;
			}
		}
	}

	&.-left {
		&::before {
			right: -14px;
			top: 0;
			background-image: encode-svg('<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M14 0C6.273 0 0 6.273 0 14V0h14z"/></svg>');
		}

		&.-smallWindow::after {
			right: -14px;
			bottom: 0;
			background-image: encode-svg('<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M0 0c0 7.727 6.273 14 14 14H0V0z"/></svg>');
		}
	}

	&.-right {
		&::before {
			left: -14px;
			top: 0;
			background-image: encode-svg('<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M14 14C14 6.273 7.727 0 0 0h14v14z"/></svg>');
		}

		&.-smallWindow::after {
			left: -14px;
			bottom: 0;
			background-image: encode-svg('<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M14 0c0 7.727-6.273 14-14 14h14V0z"/></svg>');
		}
	}
}

.article-event{
    &__status{
        display: block;
        font-size: rem(22px);
        font-weight: 700;
        margin-bottom: 1.5rem;
        color: $vismo-color-invalid;
        line-height: 1;
    }
}

._fsr {
	position: absolute !important;
	left: -9999rem !important;
	top: auto;
	outline: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

.vismo-highlighted {
	background-color: $vismo-brand-color!important;
	color: #fff!important;
}
//Chatbot umístění
.psmtc_mcdN{
	bottom: 70px!important;
	right: 0px!important;
}
