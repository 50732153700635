.settingsComponent{
	background-color: $vismo-modal-bg;
	color: $vismo-modal-fg;
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 50% !important;
	z-index: $vismo-modal-index;
	transform: translateX(-50%);
	box-shadow: 0 4px 40px rgba(#000000, .33);
	border-radius: 14px 14px 0 0;

	#settingsWindow,
	#dynamicSettingsWindow {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		height: 100%;
		overflow: auto;
	}

	#dynamicSettingsWindow>div {
		overflow: hidden;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		position: relative;
	}

	&.-big-settings-window {
		top: auto !important;
		bottom: 0;
		right: auto;
		width: 95%;
		max-width: 1100px;
		max-height: 90vh;
		min-width: 300px;
		min-height: 50vh;
		height: 85vh;
	}

	&.-medium-settings-window {
		top: 50% !important;
		transform: translate(-50%, -50%);
		//max-width: 70vw;
		max-width: 790px;
		min-width: 300px;
		height: 800px;
		max-height: 80%;
		border-radius: 14px;
		width: 92%;
	}

	&.-small-settings-window {
		top: 50% !important;
		transform: translate(-50%, -50%);
		max-width: 70vw;
		min-width: 300px;
		width: 55%;
		max-height: 50%;
		border-radius: 14px;
	}
}

.vismo-modal__scrolling-area {
	overflow-y: auto;
	overflow-x: hidden;
	flex-grow: 1;
	scrollbar-width: thin;
	scrollbar-color: #dadada $vismo-modal-bg;
	margin: 0.5rem 0.5rem 0.5rem 0;
	padding-left: 0;
	padding-right: 0;
	max-height: 100%;

	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #dadada;
		border-radius: 5px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #afafaf;
	}

}

.public-modal{
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	padding: 2rem;
	@include min(800px){
		padding: 3rem 6rem;
	}
	&__header{
		border-bottom: 1px solid #e9e9e9!important;
	}
	&__heading{
		text-align: center;
		grid-column: 2;
	}
	&__close{
		position: absolute;
		top: 2rem;
		right: 2rem;
		font-size: rem($nas-h3-size);
		font-weight: 700;
		text-decoration: none;
		padding: 0.625rem 1rem;
		border-radius: 50%;
		transition: background-color .3s ease-in-out;
		background-color: transparent;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;

		svg {
			fill: none;
			stroke: $vismo-color-dark;
			stroke-width: 2px;
		}

		@include link-over(&) {
			background-color: #e9e9e9;
		}
	}
	&__tabs{
		display: flex;
		justify-content: center;
		gap: .5rem;
		flex-wrap: wrap;
		margin: 1rem 0;
		background: #fcfcfc;
		padding: 1rem;
		@include min(800px){
			gap: 1rem;
		}
	}
	&__section{
		display: none;
		&.-active{
			display: block;
		}
	}
	&__section--block{
		max-width: 590px;
		margin: 0 auto;
	}

	.main-search-form__image-submit-container{
		margin-bottom: 1rem;
	}
	select.input{
		background-image: auto;
		-webkit-appearance: auto;
		-moz-appearance: auto;
		appearance: auto;
	}
	button.as-link{
		text-decoration: underline;
		padding: 0;
		display: inline;
		@include link-over(&){
			text-decoration: none;
		}
	}
	.radio{
		display: inline-block;
	}

	.date{
		display: flex;
		align-items: baseline;
		gap: 1rem;
	}
	.form{
		margin-bottom: 0;
		legend{
			margin-bottom: .5rem;
			font-weight: 700;
		}
		@include min(600px){
			font-size: rem(14px);
		}
		&__section{
			h3{
				margin-bottom: 1.5rem;
			}
			&.-to-left{
				align-items: baseline;
				flex-direction: row;
				gap: 0 2rem;
				flex-wrap: wrap;
				.form__section{
					flex-basis: auto;
				}
			}
			&.-to-row{
				flex-direction: row;
				gap: 1rem;
				align-items: baseline;
				> * {
					margin: 0;
					flex-grow: 1;
				}
			}
		}
	}
	&__more{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 2rem;
		border-top: 1px solid #e9e9e9!important;
		padding-top: 1.5rem;
		text-align: center;
	}

	.vismo-tags__alphabet{
		font-size: 1.25rem;
		position: sticky;
		top: -2px;
		overflow: hidden;
		background-color: #fff;
		z-index: 2;
		.list{
			@include flex(df, fww);
			gap: .5rem;
			padding-left: 0;
			margin: 0.25em 0;
			list-style-type: none;
			@include min(1024px){
				justify-content: space-between;
			}
		}
	}

	.article__tags{
		.tags__list{
			flex-direction: column;
		}
		.list{
			padding: 0;
			list-style-type: none;
			&__item{
				display: flex;
				align-items: center;
				gap: 0 2rem;
				margin: 0.5rem 0;
				b{
					font-size: 1.25rem;
					min-width: 1.5rem;
				}
				.list{
					@include flex(df, fww);
					gap: 0.5rem 1rem;
					margin: 0;
					&__item{
						margin: 0;
					}
				}
			}
		}
	}

	.grid-toggler{
		margin: 0;
		text-align: right;
		&__button{
			display: inline-flex;
			align-items: center;
			gap: .5rem;
			font-size: rem(14px);
			padding: 0.75rem 0.75rem;
			border-radius: .25rem .25rem 0 0;
			background-color: $vismo-color-dark;
			color: $vismo-color-light;
			&::after{
				content: "";
				width: 0px;
				height: 0px;
				border-style: solid;
				border-width: 0 4px 6px;
				border-color: transparent transparent #FFf transparent;
				transform: rotate(180deg);
				transition: transform .3s ease-in-out;
			}
			&.-active{
				&::after{
					transform: rotate(0deg);
				}
			}

			@include link-over(&){
				text-decoration: underline;
			}
		}
	}

	.grid-checkboxs{
		padding: 2rem 0;
		.list{
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: 2rem;
			margin-bottom: 0;
			line-height: 1;
			font-size: rem(14px);
			&__item,
			.checkbox{
				margin: 0;
			}
			.checkbox{
				&.-bold{
					font-weight: 700;
				}
				&__label{
					word-break: break-word;
					&::before,
					&::after{
						margin-top: -4px;
						top: 0;
					}
				}
			}
			@include min(450px){
				grid-template-columns: repeat(2, 1fr);
			}
			@include min(800px){
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}
