
iframe,
img,
video,
svg {
	max-width: 100%;
}

svg{
	height: auto;
}
svg:not([width]) {
	width: 100%;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	display: inline-block;
	border: 0 none;
	padding: 1rem 2rem;
	margin: 0;
	text-decoration: none;
	background: transparent none;
	color: inherit;
	font-size: 1rem;
	text-align: center;
	transition: transform 150ms ease;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
	&:disabled{
		cursor: not-allowed;
	}
}

table {
	border-collapse: collapse;
	width: 100%;
	max-width: 100%;
	margin: 1em auto 1em;
}

caption {
	margin-bottom: 1em;
	font-style: italic;
}

thead {
	border-bottom: 5px #dddddd solid;
	position: sticky;
	top: 0;
}

th,
td {
	padding: .85em 1em;
	text-align: left;
	vertical-align: top;
	min-width: 5em;
}

th {
	font-size: 1.125rem;
	background-color: #eeeeee;
	border: 0;
}

td {
	font-size: inherit;
}

tbody tr {
	border-bottom: 1px #dddddd solid;

	&:nth-child(odd) {
		background-color: #f7f7f7;
	}
}

blockquote {
	padding: 1em 1.25em;
	margin: 1.5em;
	border-left: 5px #cccccc solid;
	background-color: #eeeeee;

	@include min(800px) {
		margin-left: 2em;
		margin-right: 2em;
		padding-left: 2em;
		padding-right: 2em;
	}
}

.figure {
	margin: 1em;
	text-align: center;

	@include min(800px) {
		margin: 2em;
	}
}

figcaption {
	font-size: .9375rem;
	padding: .725em 1.5em 1.5em;
	text-align: left;

	@include min(800px) {
		padding-left: 2.5em;
		padding-right: 2.5em;
	}
}
