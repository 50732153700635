

%login-form-outline {
	outline: 3px skyblue solid;
	outline-offset: 2px;
	-moz-outline-radius: 6px;
	outline-radius: 6px;
}

.login-form-component {
	position: fixed;
	overflow: hidden;
	z-index: 90;
	left: -999rem;
	width: 0;
	height: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: rem(18px);
	font-family: $vismo-brand-font;
	color: #323232;

	&::before {
		position: fixed;
		z-index: 1;
		opacity: 0;
		transition: opacity .15s ease-out;
	}

	&__form {
		position: relative;
		z-index: 2;
		transform: scale(0);
		background-color: #ffffff;
		border-radius: 15px;
		transition: transform .15s ease-out, box-shadow .2s ease-out;
		box-shadow: 0 0 0 rgba(#000000, 0);
		max-width: 80vw;
		max-height: 70vh;
		min-height: 20em;
		width: 36em;
		padding: 3vmax;
	}

	&__heading {
		font-family: inherit;
		color: inherit;
		margin: 0 0 .66em;
		padding: 0;
		text-transform: none;
		font-size: rem(35px);
	}

	&__row {
		display: flex;
		flex-direction: column;
		margin: 0;

		&:empty {
			display: none;
		}

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&.-end {
			align-items: flex-end;
		}
	}

	&__label {
		font-family: inherit;
		font-size: inherit;
		margin: 0 0 6px;
	}

	&__input {
		font-family: inherit;
		font-size: inherit;
		height: 54px;
		border-radius: 6px;
		border: 1px #cccccc solid;
		outline: 0;

		&:focus {
			@extend %login-form-outline;
			box-shadow: none;
		}
	}

	&__button {
		font-family: inherit;
		font-size: inherit;
		border-radius: 5px;
		background: $vismo-brand-color;
		color: #ffffff;
	}

	&__close {
		font-size: rem(20px);
		font-weight: 600;
		position: absolute;
		right: 1em;
		top: 1em;
		line-height: 0;
		width: 20px;
		height: 20px;
		text-decoration: none;
	}

	//&:focus-within,
	&.-visible,
	&:target { //component is now visible
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;

		&::before {
			content: "";
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			opacity: 1;
			background: rgba(#000000, .7);
		}

		@keyframes popup-bounce{
			0% {
				transform: scale(0);
			} 60% {
				transform: scale(1.1);
			} 100% {
				transform: scale(1);
			}
		}

		.login-form-component__form {
			z-index: 2;
			//transform: scale(1);
			animation: popup-bounce .3s ease-out;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			transform-origin: 50% 100%;
			box-shadow: 0 5px 20px rgba(#000000, .5);
		}
	}

	&__checkbox {
		margin-bottom: .6em;
		position: relative;

		[type="checkbox"] {
			position: absolute;
			left: -999rem;
			top: auto;
		}

		&-label {
			@include flex(df, aic);
			word-wrap: break-word;
			word-break: break-word;
			position: relative;
			touch-action: manipulation;
			padding-left: 40px;

			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				width: 26px;
				height: 26px;
				left: 0;
				margin-top: -13px;
			}

			&::before {
				transition: box-shadow .15s ease;
				background-color: #cccccc;
				box-shadow: 0 0 0 2px #cccccc inset;
				border-radius: 4px;
			}

			&::after {
				@include flex(df, jcc, aic);
				transition: transform .15s ease, opacity .1s ease;
				opacity: 0;
				transform: scale(0);
				content: encode-svg('<svg width="14" height="10" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><path d="M1,5L5,9L13,1" style="fill:none;stroke:#343434;stroke-width:2px;"/></svg>');
			}

			:checked + & {
				&::before {
					box-shadow: 0 0 0 40px $vismo-color-confirm inset;
				}

				&::after {
					opacity: 1;
					transform: scale(1);
				}
			}

			[disabled] + & {
				cursor: not-allowed;

				&::before {
					background-color: #dddddd;
					box-shadow: none;
				}
			}

			[disabled]:checked + & {
				&::before {
					box-shadow: 0 0 0 2px #dddddd inset;
				}

				&::after {
					opacity: .5;
					content: encode-svg('<svg width="14" height="10" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><path d="M1,5L5,9L13,1" style="fill:none;stroke:#888888;stroke-width:2px;"/></svg>');
				}
			}

			.keyboard-focus:focus + & {
				@extend %login-form-outline;
			}

			@include min(600px) {
				padding-left: 35px;

				&::before,
				&::after {
					width: 20px;
					height: 20px;
					margin-top: -10px;
				}
			}
		}
	}
}
