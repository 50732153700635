.inline-form{
	.form__section{
		&.-inline.-to-right{
			//linky na datové zdroje u úřední desky
			.inline-form__link[class*='-board']{
				display: block;
				margin-bottom: 1rem;
				@include min(600px){
					display: inline-block;
					margin-right: 1rem;
					margin-bottom: 0;
				}
			}
		}
	}
}
