

.vismo-form {
    .form__section{
        position: relative;
        .label{
            position: absolute;
            top: 7.5px;
            left: 2.3rem;
            font-weight: 500;
            &.-relative{
                position: relative;
                top: auto;
                left: 1.3rem;
            }
            &__info{
                padding-left: 1rem;
            }
        }
        .text-danger{
            position: absolute;
            top: 7.5px;
            left: 2.3rem;
            font-weight: 500;
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }
        .input,
        .textElm,
        .vismo-select{
            padding: 2em 1em .5em;
            background-color: #fff;
            border-radius: 6px;
        }

        input[type='date'],
        input[type='time']{
            padding: .5em 1em .5em;
            background-color: #fff;
            border-radius: 6px;
            margin-bottom: .5rem;
            &[disabled]{
                background-color: $vismo-checkbox-bg--disabled;
                cursor: not-allowed;
            }
        }

        .vismo-select{
            padding-top: .5em;
            margin-bottom: 0.5rem;
            select{
                padding: 0 1.3em;
            }
        }
    }
}
