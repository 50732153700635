
.gallery {
   .article__body.organisational-folder &,
   .article__body.person &{
        margin: 0 0 3em;
   }
}

.article__body .documents-galery{
	.list.documents{
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
		list-style-type: none;
		margin: 0 0 2em;
		grid-gap: 25px;
		padding: 0;
	}

   .list__item {
      position: relative;
   }
	a[data-image-desc]{
		font-size: 16px;
	}
}
