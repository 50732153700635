
/*==============================
	TYPOGRAPHY
==============================*/

$vismo-brand-font: "Roboto", -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;

/*==============================
	COLORS
==============================*/

$vismo-brand-color: #EF0021;
$vismo-color-dark: #353535;
$vismo-color-light: #f9f9fa;

$vismo-color-grey: #373737;
//Used article-search restriction
$vismo-color-lighter-grey: #cccccc;
$vismo-color-light-grey: #d1d1d1;
//Used article-search restriction
$vismo-color-lightest-grey: #e5e5e5;

$vismo-overlay-bg: rgba(#ffffff, .66);

$vismo-topbar-bg: #343434;
$vismo-topbar-fg: #fefefe;

$vismo-modal-bg: #ffffff;
$vismo-modal-fg: #353535;
$vismo-modal-button-bg: #545454;
$vismo-modal-button-fg: #ffffff;
$vismo-modal-input-bg: #EEEEEE;
$vismo-modal-input-fg: #373737;

$vismo-modal-tab-color: #373737;
$vismo-modal-tab-color-active: #EF0021;

$vismo-toolbar-bg: #373737;
$vismo-toolbar-fg: #ffffff;
$vismo-toolbar-button-bg: $vismo-brand-color;
$vismo-toolbar-button-fg: #ffffff;
$vismo-toolbar-button-bg--disabled: #545454;
$vismo-toolbar-button-fg--disabled: darken(#ffffff, 10%);

$vismo-toolbar-footer-bg: #F1F1F1;
$vismo-toolbar-footer-fg: #373737;

$vismo-button-additem-bg-color: #EEEEEE;
$vismo-button-additem-color: #373737;


$vismo-checkbox-bg: #ffffff;
$vismo-checkbox-fg: #cccccc;
$vismo-checkbox-bg--checked: #46ce7b;
$vismo-checkbox-fg--checked: #ffffff;
$vismo-checkbox-bg--disabled: #cccccc;
$vismo-checkbox-fg--disabled: #cccccc;
$vismo-checkbox-bg--disabled--checked: #cccccc;
$vismo-checkbox-fg--disabled--checked: #888888;

$vismo-radio-bg: #ffffff;
$vismo-radio-fg: #cccccc;
$vismo-radio-bg--checked: #373737;
$vismo-radio-fg--checked: #ffffff;
$vismo-radio-bg--disabled: #cccccc;
$vismo-radio-bg--disabled--checked: #cccccc;
$vismo-radio-fg--disabled--checked: #888888;

$vismo-textarea-bg: #ffffff;
$vismo-textarea-fg: #373737;
$vismo-textarea-bg--disabled: #d9d9d9;
$vismo-textarea-fg--disabled: #747474;

$vismo-color-valid: #46CE7B;
$vismo-color-warning: #FF7600;
$vismo-color-invalid: #CA001A;
$vismo-color-info: #1f74ff;
$vismo-color-confirm: #46CE7B;

%vismo-default-outline {
	outline: 3px lightskyblue solid;
	outline-offset: 2px;
	-moz-outline-radius: 3px;
	//outline-radius: 3px;
}

/*==============================
	LAYOUT
==============================*/

$vismo-topbar-height: 60px;

$vismo-button-radius: 6px;
$vismo-toolbar-button-radius: 6px;

$vismo-input-radius: 6px;

/*==============================
	NASTENKA
==============================*/

$nas-main: #434343;
$nas-main-lighter: #bcbcbc;

$nas-color-secondary: #F1F1F1;

$nas-max-width: 1920px;

$nas-vismo-brand-color: #C91518;

$nas-valid: #3c961b;
$nas-invalid: #C91518;
$nas-disabled: #d1d1d1;

$nas-color-h2: #757575;
$nas-color-h3: #434343;
$nas-color-strong: #434343;
$nas-grid-spacing: 60px;

$nas-h1-size: 28px;
$nas-h2-size: 40px;
$nas-h3-size: 22px;


/*==============================
	INDEXES
==============================*/

$vismo-topbar-index: 110;
$vismo-modal-index: 120;
$vismo-overlay-index: 130;
$vismo-wewr-strip-index: 140;
$wealert-index: 140;



/*==============================
	FORMS
==============================*/

:root{
	--form-active-color: #373737;
   --form-active-text: #ffffff;

	--form-item-border-color: #cccccc;
	--form-item-border-width: 1px;
	--form-item-border-radius: 4px;
	--form-item-bg: #ffffff;
	--form-item-fg: #2a2a2a;
	--form-disabled-item-border-color: #e3e3e3;
	--form-disabled-item-bg: #ebebeb;
	--form-disabled-item-fg: lighten($form-item-fg, 10%);
	--form-item-focus-shadow: rgba(0, 0, 0, .12);
	--form-icon-color: #444444;
}
