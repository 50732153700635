.article{
	&__tags{
		.list{
			@include flex(df);
			overflow: auto;
			padding: 0;
			margin-bottom: 1.5em;
			gap: 0.5rem .5rem;
			&.article-tags-hint__list{
				gap: 0;
			}
			&__item{
				position: relative;
				flex-shrink: 0;
				margin: 0;
				.textElm{
					min-width: 0;
				}
				@include link-over(&){
					.vismo-code-list-select__remove{
						opacity: 1!important;
					}
				}
			}
			@include min(600px){
				flex-wrap: wrap;
				overflow: visible;
			}
		}
		.article-tags-hint__list{
			flex-wrap: nowrap;
			 overflow: hidden;
			li:first-child{
				color: $vismo-brand-color;
			}
			button{
				position: relative;
				font-size: rem(12px);
				padding: 0 .65rem;
				&::before{
					position: absolute;
					left: 0;
					content: "+";
					opacity: 0;
				}
				&:disabled{
					color: $vismo-checkbox-fg--disabled--checked;
					cursor: not-allowed;
				}
				&:not(:disabled){
					cursor: pointer;
					&:focus::before,
					&:hover::before{
						opacity: 1;
					}
				}
			}
		}
		.vismo-validation-wrapper.-current-editing .list{
			padding-top: .5rem;
			&:first-child{
				margin-bottom: 0;
			}
		}
	}
	&__tag{
		display: block;
		padding: 0.35rem 0.75rem;
		color: #fff;
		background-color: var(--tenant-base-color, $vismo-color-grey);
		border-radius: 5px;
		font-size: rem(14px);
		@include link(&){
			text-decoration: none;
			color: #fff;
		}
		&.-locked{
			background-color: $vismo-color-light-grey;
		}
		&.-isLocked{
			background-color: $vismo-button-additem-bg-color;
			color: $vismo-button-additem-color;
			font-weight: 700;
		}
		&.-dark{
			background-color: $vismo-color-dark;
			color: $vismo-color-light;
			font-weight: 700;
		}
	}
	@include link-over("a.article__tag"){
		text-decoration: underline;
	}
}
span.article__tag.-isLocked{
	cursor: pointer;
}
@include link-over("span.article__tag.-isLocked") {
	background-color: $vismo-button-additem-color;
	color: $vismo-button-additem-bg-color;
}
