.personal-data {
	&__list.list {
		margin-bottom: 2rem;
	}

	&__item {
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;
	}

	&__term {
		margin-right: .5rem;
	}

	&__description {
		width: 100%;
		margin: 0 0 0.5rem;
	}
}

.org-adress {

	&__list,
	&-container__list {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-left: 0;
		list-style-type: none;
		margin-bottom: 2rem;
	}

	&__item,
	&-container__item {
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;
		line-height: 1.6;
	}

	&__description {
		width: 100%;
		margin: .75rem 0 1rem;
		white-space: pre-line;
	}

	address {
		font-style: normal;
		white-space: pre-line;
	}

	&__map {
		position: relative;
		margin: 0 0 2em;
	}
}

.org-contacts {
	&__list {
		list-style-type: none;
		padding: 0;
		margin-bottom: 2.5em;
	}

	&__item {
		position: relative;
		padding-left: 95px;
		min-height: 75px;
		margin-bottom: 1rem;
	}

	&__title {
		font-size: rem(20px);
		margin: 0 0 .5rem;
		padding: 0.5rem 0 0;
		display: inline;
		color: inherit;
	}

	button.org-contacts__title {
		cursor: pointer;

		@include link-over(&) {
			text-decoration: underline;
		}
	}

	&__thumbnail {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		width: 75px;
		height: 75px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50000em;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			background-color: $vismo-button-additem-color;
		}
		&.-add-new img {
			background-color: transparent;
		}
	}

	&__contact {
		margin: .5rem 0 0;
		font-weight: 700;

		span {
			display: inline-block;
			margin: 0 .75rem 0 0;

			&:empty {
				display: none;
				margin-right: 0;
			}
		}

		span:not(:first-of-type) {
			&::before {
				content: "";
				width: .5rem;
				height: .5rem;
				display: inline-block;
				vertical-align: middle;
				margin: 0 .75rem 0 0;
				background-color: $vismo-brand-color;

				#lightbox & {
					content: normal;
				}
			}
		}
	}

	#lightbox & {
		&__item {
			padding-left: 140px;

			&::before {
				left: 41px;
			}
		}

		&__thumbnail {
			left: 40px;
		}

		&__title {
			font-weight: 700;
		}

		&__contact {
			margin-top: 0;
			font-weight: 400;
		}

		.vismo-radio {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
