.vismo-cookies {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform .3s ease-out;
    transform: translateY(0);
    &__content {
        padding: 18px;
        background-color: #2f2f2e;
        color: white;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 0.875rem !important;
        @include min(900px){
            flex-wrap: nowrap;
            flex-direction: row;
        }
    }
    &__text {
        text-align: center;
        margin: 5px 0;
        color: white;
        @include min(900px){
            text-align: left;
        }
        @include link(){
            text-decoration: underline;
            color: inherit;
        }
        @include link-over(){
            text-decoration: none;
        }
    }
    &__about {
        white-space: nowrap;
    }
    &__buttons {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 8px;
        gap: 8px;
        margin: 0 35px;
        align-items: center;
        justify-content: center;
        @include min(900px){
            flex-shrink: 0;
            flex-wrap: nowrap;
        }
    }
    &__button{
        @include link-over(button){
            background-color: #fff;
        }
        &.vismo-cookies-agree-button{
            @include link(button){
                background-color: #fff;
                color: #2f2f2e;
            }
            @include link-over(button){
                background-color: $vismo-color-valid;
                border-color: $vismo-color-valid;
                color: #fff;
            }
        }
        &.vismo-cookies-refuse-button{
            @include link(button){
                background-color: transparent;
                color: #fff;
            }
            @include link-over(button){
                background-color: #fff;
                border-color: #fff;
                color: #2f2f2e;
            }
        }
    }
    &__button button,
    &__more{
        display: block;
        box-sizing: border-box;
        border-radius: 7px;
        text-align: center;
        border: 2px white solid;
        text-decoration: none;
        font-weight: bold;
        text-transform: lowercase;
        cursor: pointer;
        padding: 4px 10px 4px;
        min-width: 135px;
        width: 100%;
    }
    &__more{
        background-color: $vismo-color-dark;
        border-color: $vismo-color-dark;
        display: inline;
        @include link(&){
            color: #fff;
        }
        @include link-over(&){
            background-color: #fff;
            color: $vismo-color-dark;
        }
    }
    &-modal{
        text-align: center;
        #lightbox &.lbox_main{
            padding-top: 8%;
        }
        h2{
            font-size: 1.375rem;
            color: #434343;
            font-weight: 700;
        }
        h3{
            font-size: 1.1rem;
            margin-bottom: 0;
            color: #434343;
            line-height: 1.3;
        }
        hr{
            margin: 1.5rem 0px;
        }
        table{
            margin: 1rem auto 2rem;
            width: 30em;
            max-width: 100%;
            tr{
                background-color: #fff;
            }
            td{
                border: 1px solid #dedede;
            }
        }
        .checkbox {
            position: relative;

            [type="checkbox"] {
                position: absolute;
                left: -999rem;
                top: auto;
                &:checked + .checkbox__label {
                    &::before {
                        box-shadow: 0 0 0 40px $vismo-color-confirm inset;
                    }

                    &::after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                &[disabled] + .checkbox__label {
                    cursor: not-allowed;

                    &::before {
                        background-color: #dddddd;
                        box-shadow: none;
                    }
                }

                &[disabled]:checked + .checkbox__label {
                    &::before {
                        box-shadow: 0 0 0 2px #dddddd inset;
                    }

                    &::after {
                        opacity: .5;
                        content: encode-svg('<svg width="14" height="10" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><path d="M1,5L5,9L13,1" style="fill:none;stroke:#888888;stroke-width:2px;"/></svg>');
                    }
                }
            }

            &__label {
                @include flex(df, aic);
                word-wrap: break-word;
                word-break: break-word;
                position: relative;
                touch-action: manipulation;
                padding-left: 40px;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    width: 26px;
                    height: 26px;
                    left: 0;
                    margin-top: -13px;
                }

                &::before {
                    transition: box-shadow .15s ease;
                    background-color: transparent;
                    box-shadow: 0 0 0 2px #cccccc inset;
                    border-radius: 4px;
                }

                &::after {
                    @include flex(df, jcc, aic);
                    transition: transform .15s ease, opacity .1s ease;
                    opacity: 0;
                    transform: scale(0);
                    content: encode-svg('<svg width="14" height="10" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><path d="M1,5L5,9L13,1" style="fill:none;stroke:#fff;stroke-width:2px;"/></svg>');
                }

                .keyboard-focus:focus + & {
                    @extend %login-form-outline;
                }

                @include min(600px) {
                    padding-left: 35px;

                    &::before,
                    &::after {
                        width: 20px;
                        height: 20px;
                        margin-top: -10px;
                    }
                }
            }
        }
        .vismo-cookies{
            &__buttons{
                @include flex(df, fww, aic, jcc);
            }
            &__button{
                &.vismo-cookies-agree-chosen-button{
                    @include link(button){
                        background-color: $vismo-color-dark;
                        border-color: $vismo-color-dark;
                        color: #fff;
                    }
                    @include link-over(button){
                        background-color: #fff;
                        color: $vismo-color-dark;
                    }
                }
                &.vismo-cookies-agree-all-button{
                    @include link(button){
                        background-color: $vismo-brand-color;
                        border-color: $vismo-brand-color;
                        color: #fff;
                    }
                    @include link-over(button){
                        background-color: $vismo-color-valid;
                        border-color: $vismo-color-valid;
                        color: #fff;
                    }
                }
            }
        }
    }
}

