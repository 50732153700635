.page-footer{
	&__list{
		list-style-type: none;
		margin-top: 1em;
		margin-bottom: 1em;
		padding-left: 0;
	}
	&__item{
		display: inline;
		&:not(:last-child)::after{
			content: "\|";
			margin: 0 .5em;
		}
	}
	.partWebmaster::after{
		content: "\|";
		margin: 0 .5em;
	}
}
