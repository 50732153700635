.main.ot-9{
	.article{
		&__body{
			.list{
				margin-bottom: 0;
				.element-type-heading{
					font-size: 1rem;
					font-family: inherit;
				}
			}
			> .list{
				margin-bottom: 3rem;
			}
		}
		&-link__title{
			font-size: 1rem;
		}
		.text-component{
			margin-bottom: 1rem;
		}
	}
}
