.reg-form,
.log-form,
#lightbox .reg-form,
#lightbox .log-form {
	&.-component {
		display: flex;
		flex-direction: column;
	}

	.-italic {
		font-style: italic;
	}

	.-regular {
		font-weight: 400;
	}

	@include min(1100px) {
		&.-component {
			flex-direction: row;
		}

		&__other {
			border-left: 1px solid #e6e6e6;
			margin: 0 0 2em 4%;
			padding-left: 4%;
			max-width: 260px;
		}
	}

	&__form {
		flex-grow: 1;
		max-width: 100%;

		.form__fields {
			//flex-direction: column;
		}

		.form__section {
			position: relative;
			min-width: 0;
			display: block;

			@include min(600px) {
				flex-basis: 50%;
			}

			&.-full {
				width: 100%;
				flex-basis: 100%;
			}

			.label {
				position: absolute;
				top: 7.5px;
				left: 2.3rem;
				font-weight: 500;
				z-index: 5;
				+ #url{
					position: relative;
				}

				&__info {
					padding-left: 1rem;
				}
			}

			.text-danger {
				position: absolute;
				top: 7.5px;
				left: 2.3rem;
				font-weight: 500;

				ul {
					margin: 0;
					padding: 0;
					list-style: none;
				}
			}

			.input,
			.textElm,
			.vismo-select {
				padding: 2em 1em .5em;
				background-color: #fff;
				border-radius: 6px;

				&[disabled] {
					background-color: $nas-disabled;
					cursor: not-allowed;
				}
			}

			.input[type="password"] {
				padding-right: 3em;
			}

			.textareaElm {
				background-color: #fff;
				padding: 0.5rem 1rem;
				border-radius: 0 0 6px 6px;

				&__label {
					background-color: #fff;
					font-weight: 500;
					display: block;
					padding: 0.5rem 1rem 0;
					border-radius: 6px 6px 0 0;
				}

				&__container {
					background-color: transparent;
					overflow: hidden;
				}

			}

			.password__toggle {
				position: absolute;
				top: 50%;
				margin-top: -.75rem;
				right: 2rem;
				width: 1.5rem;
				height: 1.5rem;
				padding: 0;
				background: transparent encode-svg('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g><path d="M328.5,531.8c0-7.3-2.5-14.7-2.5-24.5c0-95.5,76-171.5,171.5-171.5c9.8,0,19.6,0,27,2.4l102.9-100.4c-41.7-9.8-83.3-14.7-127.4-14.7c-208.3,0-389.6,115.1-490,281.8c41.6,68.6,98,129.8,166.6,176.4L328.5,531.8z M801.3,313.8L659.2,451c7.4,17.2,9.8,36.8,9.8,56.3c0,95.6-76,171.5-171.5,171.5c-22,0-41.7-4.9-61.3-12.3l-95.5,95.5c51.5,14.7,102.9,24.5,159.3,24.5c208.3,0,389.5-115.2,490-281.8C943.4,426.5,877.3,360.4,801.3,313.8z M558.8,551.5l-19.6,17.1C546.5,563.7,551.5,558.8,558.8,551.5z M796.4,147.2L130,798.9l53.9,53.9l666.4-654.1L796.4,147.2z"/></g></svg>') no-repeat center center;

				&.-toggled {
					background: transparent encode-svg('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g><path d="M500,781.9c-207.2,0-388.7-115.3-490-281.9c101.3-166.6,282.8-281.9,490-281.9c207.2,0,388.7,115.3,490,281.9C888.7,666.5,707.2,781.9,500,781.9z M500,326.9c-94.8,0-171.6,76.8-171.6,171.6S405.2,670,500,670c94.8,0,171.6-76.8,171.6-171.6S594.8,326.9,500,326.9z M500,573.6c-42.4,0-76.7-33.7-76.7-75.2c0-41.5,34.3-75.2,76.7-75.2c42.4,0,76.7,33.7,76.7,75.2C576.7,540,542.4,573.6,500,573.6z"/></g></svg>') no-repeat center center;
				}
			}

			#textAddressElm .textareaElm {
				min-height: 5.25em;
			}

			.form__textarea {
				width: 100%;
			}

			.textElm:empty:not(:focus):before {
				min-height: 1.5rem;
			}

			.form__button.-primary {
				padding: 5px 1.1rem;
				min-width: 135px;
			}

			&.checkbox {
				display: block;

				input[type="checkbox"] {
					height: 20px;
					width: 20px;
					display: inline-block;
					vertical-align: middle;
					margin: 0px 5px 4px 0;
				}
			}

			&.decision {
				margin-top: 1rem;
			}

			&.checkbox.age {
				display: block;

				&.-fl {
					display: flex;
					flex-direction: row;
					margin: 0;
				}

				input[type="checkbox"] {
					height: 20px;
					width: 20px;
					display: inline-block;
					vertical-align: middle;
					margin: 0px 5px 4px 0;
				}

				+.form__section {
					display: none;
				}
			}
		}

		.form__fieldset {
			padding: 0 0 1rem;
			border: none;
			border-radius: 0;
			margin: 0;
			min-inline-size: fit-content;

			&.-half {
				width: 50%;
			}
		}

		.form__legend {
			padding: 0 0 .25rem;
			font-size: 1rem;
			font-weight: 400;
		}

		.radio {}
	}

	&__other {
		margin: 0 0 2em;
		flex-grow: 1;

		&__list {
			list-style-type: none;
			padding: 0;
		}

		&__item {
			text-align: center;
			margin: 0 0 1em;
			border: 1px solid #cccccc;
			border-radius: 5px;
			overflow: hidden;
		}

		&__link {
			position: relative;
			display: block;
			padding: .5em 32px .5em 0;
			box-sizing: border-box;
			background-color: #e2e2e2;
			border-left: solid 42px #ebebeb;

			&::before {
				top: 50%;
				margin-top: -8.5px;
				left: -27.5px;
				position: absolute;
				width: 1rem;
				height: 1rem;
				content: encode-svg('<svg xmlns:x="http://ns.adobe.com/Extensibility/1.0/" xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/" xmlns:graph="http://ns.adobe.com/Graphs/1.0/" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 533.5 544.3" style="enable-background:new 0 0 533.5 544.3;" xml:space="preserve"><metadata><sfw xmlns="http://ns.adobe.com/SaveForWeb/1.0/"><slices/><sliceSourceBounds bottomLeftOrigin="true" height="544.3" width="533.5" x="0.1" y="110.1"/></sfw></metadata><g><path class="st0" style="fill:#4285F4" d="M533.5,278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1,33.8-25.7,63.7-54.4,82.7v68h87.7   C503.9,431.2,533.5,361.2,533.5,278.4z"/><path class="st1" style="fill:#34A853" d="M272.1,544.3c73.4,0,135.3-24.1,180.4-65.7l-87.7-68c-24.4,16.6-55.9,26-92.6,26c-71,0-131.2-47.9-152.8-112.3   H28.9v70.1C75.1,486.3,169.2,544.3,272.1,544.3z"/><path class="st2" fill="#FBBC04" d="M119.3,324.3c-11.4-33.8-11.4-70.4,0-104.2V150H28.9c-38.6,76.9-38.6,167.5,0,244.4L119.3,324.3z"/><path class="st3" style="fill:#EA4335" d="M272.1,107.7c38.8-0.6,76.3,14,104.4,40.8l0,0l77.7-77.7C405,24.6,339.7-0.8,272.1,0C169.2,0,75.1,58,28.9,150   l90.4,70.1C140.8,155.6,201.1,107.7,272.1,107.7z"/></g></svg>');
			}
		}

		@include link {
			color: #5d5d5d;

			&.facebook {
				background-color: #4255c1;
				border-color: transparent;
				color: #fff;
				border-left-color: #4253b4;

				&::before {
					content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 96.124 96.123" style="fill:#fff;" xml:space="preserve"><g><path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803   c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654   c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246   c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"/></g></svg>')
				}
			}

			&.vismo {
				color: #fff;
				background-color: #c91518;
				border-color: transparent;
				border-left-color: #bc1a1d;

				&::before {
					width: 19.646px;
					height: 18px;
					margin-top: -9px;
					left: -28px;
					content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="19.646" height="18" viewBox="0 0 19.646 18"><path id="Path_5576" data-name="Path 5576" d="M28.707.6,23.172,13.439,17.688.6H13.2l7.753,18h4.114L32.845.6Z" transform="translate(-13.2 -0.6)" fill="#fff"/></svg>')
				}
			}
		}

		@include link-over {
			text-decoration: none;
		}
	}
}
