.thumbnails {
	padding: 1rem 0;
}

.vismo-system-layout-main-wrapper .vismo-system-layout-thumbnails{
	.list.documents{
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		flex-direction: row;
		li{
			display: inline-flex;
			padding: 0 .5rem 1rem;
			font-size: 1rem;
		}
		a .thumbnail__image{
			box-shadow: 0 0 0 0;
			@include link-over(&){
				box-shadow: 0 0 0 2px $vismo-brand-color;
			}
		}
	}
}
.thumbnail {
	position: relative;

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;

		#lightbox & {
			@include max(799px) {
				padding-top: 1.5rem;
			}
		}
	}

	&__item {
		display: inline-flex;
		padding: 0 .5rem 1rem;

		&.-active,
		&.-selected{
			.thumbnail__image {
				box-shadow: 0 0 0 2px $vismo-brand-color;
			}

			.thumbnail__title {
				color: $vismo-brand-color;
			}

			.thumbnail__usage {
				background-color: $vismo-brand-color;
			}
		}
	}

	&__image {
		display: block;
		margin: 0;
		border-radius: 10px;
		overflow: hidden;
		box-shadow: 0 0 0 0 transparent;

		img{
			width: 100%;
			max-height: 216px;
			display: block;
			object-fit: cover;
			object-position: center;
		}
		svg{
			width: 100%;
			max-height: 216px;
			height: 100%;
		}
	}

	&__title {
		padding-top: .5rem;
		display: block;
		max-width: fit-content;
	}

	&__usage {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
		width: 30px;
		height: 30px;
		border-radius: 50%;
		color: #ffffff;
		background-color: $vismo-color-dark;
	}
	.-svg-ico &{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: 1rem;
		padding: 1rem;
		&__item{
			width: 100%;
			border-bottom: 2px solid;
			padding: 0;
			&.-head{
				text-transform: uppercase;
				font-weight: 500;
				padding-bottom: 0;
				display: none;
				.thumbnail{
					margin-top: 0;
					margin-bottom: 0;
					padding-top: 0;
					padding-bottom: 0;
					*:first-of-type{
						width: 120px;
					}
					*:last-of-type{
						text-align: right;
						margin-left: auto;
					}
				}
				@include min(600px){
					display: flex;
				}
			}
			&:last-of-type(){
				border-bottom: 0;
			}
			&.-active,
			&.-selected {
				.thumbnail__image {
					box-shadow: none;
				}

				.thumbnail__title {
					color: $vismo-brand-color;
				}

				.thumbnail__usage {
					background-color: transparent;
					color: $vismo-brand-color;
				}
			}
		}
		&__image{
			width: 100%;
			@include min(600px){
				width: 120px;
			}
		}
		&__title {
			display: flex;
			padding: .5rem 0;
			align-items: center;
			flex-grow: 1;
		}
		&__usage {
			position: relative;
			text-align: right;
			transform: none;
			color: $vismo-color-dark;
			background-color: transparent;
			border-radius: 0;
			font-weight: 700;
			display: flex;
			padding: .5rem 0;
			align-items: center;
			justify-content: flex-end;
			width: auto;
			height: auto;
			max-width: 2rem;
			margin-left: auto;
		}
	}
}

#imageName {
	.textElm {
		background-color: #ececec !important;
		border-radius: 5px;
		border: 2px solid lightgrey;
		color: #a2a2a2;

		&:hover,
		&:focus {
			color: #373737;
		}
	}
}
