.vismo-button {
	border-radius: $vismo-button-radius;
	padding: 12px 30px;
	font-size: 16px;

	&:not([disabled]) {
		cursor: pointer;
	}

	&.-link {
		border: 0;
		background-color: transparent;
		text-decoration: underline;
		padding: 0;
		display: inline;
		text-align: left;

		@include link-over(&) {
			text-decoration: none;
		}
	}

	&.-inline {
		font-weight: bold;
		padding: 12px 0;
		margin: 0 30px;
		color: $vismo-brand-color;
		text-decoration: underline;
	}

	&.-add-item {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background-color: $vismo-button-additem-bg-color;
		color: $vismo-button-additem-color;
		font-weight: 500;
		margin: 1.25rem 0 1.5rem;
		padding: 8px 30px;
		font-size: 1rem;
		font-family: $vismo-brand-font;
		min-height: 46px;

		@include link-over(&) {
			background-color: $vismo-button-additem-color;
			color: $vismo-button-additem-bg-color;
		}

		&::after {
			font-size: rem(25px);
			content: "+";
			display: inline-block;
			vertical-align: middle;
			margin-left: 6px;
		}
	}
}
