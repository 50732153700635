@import "../shared/mixins.scss";

.article__body .article__with-same-tags{
	.list.documents{
		@include grid(600px 3 1rem, 1024px 3 1.5rem);
		.document{
			&__description{
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}
		}
	}
	.more{
		text-align: left;
	}
}

.article__body .list.documents,
.article__body .list.board_documents{
	.document__tags{
		.list{
			display: flex;
			flex-wrap: wrap;
			gap: 0.25em;
			padding: 0;
			margin: 0.5em 0 0.75em;
			&:empty{
				display: none;
			}
			&__item{
				float: none;
				margin: 0;
			}
		}
	}
}
