.vismo-code-list-select{
	position: relative;
	//> div{
	//	display: flex;
	//	flex-wrap: wrap;
	//	align-items: center;
	//	gap: 0 1.5rem;
	//}
	&__plus{
		background-color: $vismo-modal-input-bg;
		color: $vismo-modal-input-fg;
		border-radius: $vismo-input-radius;
		@include flex(dif, fww, aic, jcc);
		width: 24px;
		height: 24px;
		padding: 0;
		transition: background-color .3s ease-in-out;
		@include link-over(&){
			background-color: darken($vismo-modal-input-bg, 5);
		}
	}
	&__list{
		z-index: 2;
		display: inline;
		padding: 0;
		margin: 0 1rem 0 0;
		list-style-type: none;
		gap: 0 1rem;
	}
	&__item{
		position: relative;
		display: inline;
		text-align: left;
		&:not(:last-of-type){
			.vismo-code-list-select__remove{
				right: 1.5rem;
			}
			&::after{
				display: inline-block;
				margin: 0 .5rem;
				content: "|";
			}
		}
		@include link-over(&){
			.vismo-code-list-select__remove{
				opacity: 1;
			}
		}
	}
	&__remove{
		position: absolute;
		right: 0;
		top: 0;
		@include flex(df, fww, aic, jcc);
		width: 18px;
		height: 18px;
		padding: 0;
		transform: translate(50%, -50%);
		font-size: rem(12px);
		background-color: $vismo-brand-color;
		border-radius: 50%;
		opacity: 1;
		transition: opacity .3s ease-in-out;
		cursor: pointer;
		@include min(800px){
			opacity: 0;
		}
		@include link(&){
			color: #fff;
			text-decoration: none;
		}
	}
	&-bubble{
		position: absolute;
		bottom: 100%;
		left: 50%;
		background-color: $vismo-modal-input-bg;
		color: $vismo-modal-input-fg;
		border-radius: $vismo-input-radius;
		padding: 0.4rem;
		outline: 2px solid lightskyblue;
		&__close{
			position: absolute;
			top: 0.25rem;
			right: 0.25rem;
			font-size: rem(12px);
			width: 18px;
			height: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			line-height: 1;
			background-color: $vismo-color-dark;
			transition: background-color .3s ease-in-out;

			@include link(&){
				color: #fff;
				text-decoration: none;
			}
			@include link-over(&){
				background-color: $vismo-brand-color;
			}
		}

		&__list{
			padding: 0;
			margin: 0;
			list-style-type: none;
			width: max-content;
    		max-width: 200px;
		}
		&__item{

		}
		&__link{
			width: 100%;
			padding: 0.2rem 20px 0.2rem 0.4rem;
			text-align: left;
			transition: background-color .3s ease-in-out;
			@include link-over(&){
				background-color: darken($vismo-modal-input-bg, 5);
			}
		}
	}
}
