.text-component{
	.highlightedBg{
		font-style: normal;
		font-weight: bold;
		color: var(--tenant-base-color, $vismo-brand-color);
	}
}

figure.table-wrapper,
figure.wewr-ytb-video,
figure.image-align-center{
	margin-left: 0;
	margin-right: 0;
}

.wewr-ytb-video {
	&__wrapper {
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
		max-width: 100%;

	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
